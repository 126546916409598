<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户姓名" prop="userName">
                <a-input v-model="queryParam.userName" placeholder="请输入用户姓名" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="审核结果" prop="auditResults">
                <a-select placeholder="请选择审核结果" v-model="queryParam.auditResults" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.audit" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="用户手机号" prop="userMobile">
                  <a-input v-model="queryParam.userMobile" placeholder="请输入用户手机号" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:attest/vc:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:attest/vc:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:attest/vc:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:attest/vc:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :investmentExperienceOptions="dict.type.is_or"
        :auditResultsOptions="dict.type.audit"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
         <span slot="idCardFront" slot-scope="text, record">
          <img v-if="record.idCardFront" :src="record.idCardFront" alt="图片" style="height:48px;max-width:48px"/>
<!--            <img style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;" v-if="record.idCardFront"-->
<!--                 v-for="(guildLogo,imgIndex) in record.idCardFront.split(',')"-->
<!--                 :src="guildLogo"-->
<!--                 preview="图片" />-->
        </span>
        <span slot="idCardBack" slot-scope="text, record">
          <img v-if="record.idCardBack" :src="record.idCardBack" alt="图片" style="height:48px;max-width:48px"/>
          <!--            <img style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;" v-if="record.idCardBack"-->
          <!--                 v-for="(guildLogo,imgIndex) in record.idCardBack.split(',')"-->
          <!--                 :src="guildLogo"-->
          <!--                 preview="图片" />-->
        </span>
        <span slot="investmentExperience" slot-scope="text, record">
          <dict-tag :options="dict.type['is_or']" :value="record.investmentExperience"/>
        </span>
        <span slot="auditResults" slot-scope="text, record">
          <dict-tag :options="dict.type['audit']" :value="record.auditResults"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" />
          <a @click="$refs.createForm.handleDetail(record, undefined)" >
            <a-icon type="edit" />详情
          </a>
          <a-divider type="vertical" />
          <a v-show="record.auditResults == 0" @click="$refs.createForm.handleUpdate(record, undefined)" >
            <a-icon type="edit" />审核
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['biz:attest/vc:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['biz:attest/vc:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageAttestVc,listAttestVc, delAttestVc } from '@/api/biz/attestVc'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'AttestVc',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['is_or', 'audit'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userName: null,
        userMobile: null,
        auditResults: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '投资人id',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户姓名',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户手机号',
          dataIndex: 'userMobile',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '身份证号',
        //   dataIndex: 'idCard',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '身份证人像面',
          dataIndex: 'idCardFront',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "idCardFront"}
        },
        {
          title: '身份证国徽面',
          dataIndex: 'idCardBack',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "idCardBack"}
        },
        {
          title: '投资机构',
          dataIndex: 'companyName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '专长领域',
          dataIndex: 'investment',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '职位',
          dataIndex: 'position',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否有投资经验',
          dataIndex: 'investmentExperience',
          scopedSlots: { customRender: 'investmentExperience' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '投资金额',
          dataIndex: 'investmentAmount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'auditResults',
          scopedSlots: { customRender: 'auditResults' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '投资阶段',
        //   dataIndex: 'investmentStageValue',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      //   v-hasPermi="['biz:attest/vc:edit']"
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询投资人认证列表 */
    getList () {
      this.loading = true
     pageAttestVc(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userName: undefined,
        userMobile: undefined,
        auditResults: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delAttestVc(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/user-attest-vc/export', {
            ...that.queryParam
          }, `投资人认证_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
